@import "src/assets/styles/_global-colors.scss";

* {
    font-family: "Jost*" !important;
}

#okta-container {
    padding-top: 100px;
    padding-bottom: 100px;
}

// .apploginbanner-container {
//     display: none !important;
// }

// #okta-login-container.login-container {
//     margin-top: 0 !important;
// }


// body {
//     background-color: #13233a !important;
//     font-family: "Jost*" !important;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     align-content: center;
//     flex-wrap: nowrap;
//     vertical-align: middle;
//     justify-content: center;
//     min-height: 100%;
//     height: 100%;
//     margin: 0;
// }

// #login-bg-image-id {
//     background-image: url(https://op1static.oktacdn.com/fs/bco/7/fs01qw4hhuyLGsEcs0h8);
//     background-size: cover !important;
//     background-repeat: no-repeat !important;
//     background-attachment: fixed;
//     background-position: center;
//     background-position: 50% !important;
//     bottom: 0 !important;
//     left: 0 !important;
//     position: fixed !important;
//     right: 0 !important;
//     top: 0 !important;
//     z-index: -5 !important;
// }

#login-bg-image-id {
    z-index: 0;
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    overflow-x: hidden;
    background-image: url("../../assets/images/Explore-login-page-hero.jpg");
}

.login-bg-image {
    @media only screen and (max-width: 600px) { 
        background-image: url("../../assets/images/Explore-login-page-hero.jpg") !important;
    }
}

// #login-bg-image-id video {
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
// }

// .copyright-text {
//     height: 12px;
//     font-family: "Jost*";
//     font-style: normal;
//     font-weight: 650;
//     font-size: 10px;
//     line-height: 120%;
//     text-align: center;
//     letter-spacing: 0.5px;
//     color: #ffffff;
// }

// .data-footer {
//     width: 100%;
//     padding-bottom: 68px;
//     display: block;
//     padding-top: 41px;
//     opacity: 0;
//     transition: opacity 1s;
//     z-index: 1;
// }

// body.tac-status .data-footer {
//     position: absolute;
//     top: 82%;
// }

// body.tac-status {
//     overflow-y: hidden;
// }

// .sublink {
//     cursor: pointer;
//     padding: 0 8px;
//     text-align: center;
//     color: #ffffff;
//     height: 35px;
//     font-family: "Jost*";
//     font-style: normal;
//     font-weight: 500;
//     font-size: 14px;
//     line-height: 150%;
//     letter-spacing: 0.5px;
// }

// .links-list {
//     text-align: center;
//     color: #ffffff;
//     height: 35px;
//     font-family: "Jost*";
//     font-style: normal;
//     font-weight: 500;
//     font-size: 14px;
//     line-height: 150%;
//     letter-spacing: 0.5px;
// }

// .sublink.our-use-of-cookies {
//     color: #fff !important;
//     border: 0 !important;
//     background-color: transparent !important;
//     text-align: center !important;
//     color: #ffffff !important;
//     height: 35px !important;
//     font-family: "Jost*" !important;
//     font-style: normal !important;
//     font-weight: 500 !important;
//     font-size: 14px !important;
//     line-height: 150% !important;
//     letter-spacing: 0.5px !important;
//     padding: 0 8px !important;
// }

// .siw-main-footer a.link.js-help {
//     display: none;
// }

// .button.button-primary {
//     background: $color-natural-tone-blue !important;
// }

// .button.button-primary:hover {
//     background: $color-highlight-tone-blue !important;
// }

// .okta-form-title.o-form-head {
//     display: none;
// }

#okta-sign-in input[type=text]:focus {
    box-shadow: none !important;
}

.focused-input {
    box-shadow: none !important;
    border-color: $color-pms-black-6 !important;
}

#okta-sign-in.auth-container .button-primary {
    background: $color-natural-tone-red !important;
    border-color: $color-natural-tone-red !important;
    box-shadow: none !important;
}

#okta-sign-in.auth-container input[type="button"]:focus,
#okta-sign-in.auth-container input[type="submit"]:hover {
    background: $color-natural-tone-red !important;
    border-color: $color-natural-tone-red !important;
    box-shadow: none !important;
    opacity: 0.9;
}

.subtext {
    height: fit-content !important;
    font-family: "Jost*" !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 20px !important;
    line-height: 160% !important;
    text-align: center !important;
    color: #13233a !important;
    margin-top: 15px !important;
    display: block !important;
    opacity: 0;
    letter-spacing: 0.3px;
    transition: opacity 1s;   
}

.okta-sign-in-header.auth-header {
    padding-top: 20px !important;
    padding-bottom: 75px !important;

    @media only screen and (max-width: 500px) { 
        height: auto !important;
    }
}

#okta-sign-in.no-beacon .auth-header {
    padding-bottom: 20px !important;
}

// // .o-form-error-container.o-form-has-errors {}

// .okta-form-input-field.input-fix {
//     border: 0 !important;
//     border-bottom: 1px solid #13233a !important;
//     border-radius: 0 !important;
//     box-shadow: none !important;
// }

// .okta-form-input-field.input-fix input {
//     padding: 0 !important;
//     box-shadow: none !important;
// }

// .okta-form-input-field.input-fix input:focus {
//     box-shadow: none !important;
// }

// .o-form-label.okta-form-label {
//     font-family: "Jost*" !important;
//     font-style: normal !important;
//     font-weight: 500 !important;
//     font-size: 10px !important;
//     line-height: 120% !important;
//     letter-spacing: 0.5px !important;
//     color: #757474 !important;
//     position: relative !important;
// }

// .o-form-label.okta-form-label label.focused {
//     font-family: "Jost*" !important;
//     font-style: normal !important;
//     font-weight: 500 !important;
//     font-size: 10px !important;
//     line-height: 120% !important;
//     letter-spacing: 0.5px !important;
//     color: #757474 !important;
//     position: absolute !important;
//     top: 6px;
//     z-index: 3;
// }

// .o-form-label.okta-form-label label {
//     font-size: 16px !important;
//     position: absolute !important;
//     top: 20px;
//     z-index: 3;
//     font-family: "Jost*" !important;
//     font-style: normal !important;
//     font-weight: 500 !important;
//     line-height: 120% !important;
//     letter-spacing: 0.5px !important;
//     color: #757474 !important;
//     transition: top 10ms 10ms, font-size 10ms 10ms;
//     white-space: nowrap;
//     text-overflow: ellipsis;
//     width: 100%;
//     overflow: hidden;
// }

// #okta-sign-in .custom-checkbox input[type="checkbox"] {
//     z-index: 2 !important;
//     background-image: none !important;
//     appearance: none !important;
//     background-position: 50% !important;
//     background-repeat: no-repeat !important;
//     border: 1px solid #a8a7a7 !important;
//     width: 16px !important;
//     height: 16px !important;
//     border-radius: 2px !important;
//     display: block !important;
//     box-sizing: border-box !important;
//     visibility: visible !important;
//     opacity: 1 !important;
// }

// #okta-sign-in .custom-checkbox input[type="checkbox"]:checked {
//     background-image: url("data:image/svg+xml,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.33128 5.50315C3.26561 5.50331 3.20056 5.49043 3.1399 5.46526C3.07925 5.44008 3.0242 5.40311 2.97794 5.35649L0.977944 3.35649C0.889624 3.2617 0.841542 3.13634 0.843828 3.00681C0.846113 2.87727 0.898588 2.75368 0.990197 2.66207C1.08181 2.57046 1.2054 2.51799 1.33493 2.5157C1.46446 2.51342 1.58983 2.5615 1.68461 2.64982L3.33128 4.29582L6.97794 0.64982C7.07273 0.5615 7.19809 0.513417 7.32763 0.515703C7.45716 0.517988 7.58075 0.570464 7.67236 0.662072C7.76397 0.753681 7.81644 0.877271 7.81873 1.00681C7.82101 1.13634 7.77293 1.2617 7.68461 1.35649L3.68461 5.35649C3.63836 5.40311 3.58331 5.44008 3.52265 5.46526C3.462 5.49043 3.39695 5.50331 3.33128 5.50315V5.50315Z' fill='%2313233A'/%3E%3C/svg%3E") !important;
// }

// .custom-checkbox label {
//     background: transparent !important;
//     font-family: "Jost*" !important;
//     font-style: normal !important;
//     font-weight: 500 !important;
//     font-size: 14px !important;
//     line-height: 150% !important;
//     text-align: left !important;
//     letter-spacing: 0.5px !important;
//     color: #757474 !important;
// }

// .custom-checkbox {
//     box-shadow: none !important;
// }

.opacity-back {
    opacity: 1;
}

// // .terms-and-condition-page {
// //     z-index: 100;
// //     width: 800px;
// //     left: calc(50% - 400px);
// //     top: 5%;
// //     border-radius: 4px;
// //     padding: 32px;
// //     background-color: #ffffff;
// //     position: absolute;
// //     display: none;
// //     flex-direction: column;
// //     max-height: 80%;
// //     max-width: 100%;
// //     box-sizing: border-box;
// // }

// // .terms-and-condition-page .caption {
// //     text-align: center;
// //     padding-bottom: 10px;
// // }

// // .terms-and-condition-page .sub-caption {
// //     font-family: "Jost*";
// //     font-style: normal;
// //     font-weight: 500;
// //     font-size: 16px;
// //     line-height: 140%;
// //     text-align: center;
// //     color: #13233a;
// //     padding-bottom: 10px;
// // }

// // .terms-and-condition-page .content {
// //     font-family: "Jost*";
// //     font-style: normal;
// //     font-weight: 500;
// //     font-size: 14px;
// //     line-height: 150%;
// //     letter-spacing: 0.3px;
// //     color: #13233a;
// //     overflow-y: auto;
// // }

// // .terms-and-condition-page .buttons {
// //     padding: 0;
// //     display: flex;
// //     justify-content: center;
// //     padding-top: 5px;
// // }

// // .terms-and-condition-page .buttons>div {
// //     flex: 1 1 auto;
// // }

// // .terms-and-condition-page .buttons>div:first-child {
// //     padding-right: 8px;
// // }

// // .terms-and-condition-page .buttons>div:last-child {
// //     padding-left: 8px;
// // }

// // .terms-and-condition-page .buttons>div:first-child>button {
// //     background: #ffffff;
// //     border-radius: 2px;
// //     font-family: "Jost*";
// //     font-style: normal;
// //     font-weight: 500;
// //     font-size: 16px;
// //     line-height: 150%;
// //     letter-spacing: 1px;
// //     color: #13233a;
// //     border: 1px solid #13233a;
// //     box-sizing: border-box;
// //     height: 48px;
// // }

// // .terms-and-condition-page .buttons>div:last-child>button {
// //     font-family: "Jost*";
// //     font-style: normal;
// //     font-weight: 500;
// //     font-size: 16px;
// //     line-height: 150%;
// //     letter-spacing: 1px;
// //     color: #ffffff !important;
// //     border-radius: 2px;
// //     border: 1px solid #13233a;
// //     box-sizing: border-box;
// //     height: 48px;
// // }

// // @media only screen and (max-width: 600px) {
// //     .terms-and-condition-page {
// //         z-index: 100;
// //         width: 100%;
// //         left: 0;
// //         top: 0;
// //         border-radius: 4px;
// //         padding: 32px;
// //         background-color: #ffffff;
// //         position: absolute;
// //         display: none;
// //         flex-direction: column;
// //         max-height: 100%;
// //         max-width: 100%;
// //     }
// // }

// .info-page-back {
//     background-color: #000;
//     position: absolute;
//     width: 100%;
//     height: 100%;
//     z-index: 98;
//     left: 0;
//     top: 0;
//     opacity: 0.7;
//     display: none;
// }

// .info-page,
// .info-page-c {
//     box-sizing: border-box;
//     border-radius: 4px;
//     padding: 32px;
//     left: 32px;
//     top: 32px;
//     position: absolute;
//     width: calc(100% - 64px);
//     height: calc(100% - 64px);
//     display: none;
//     z-index: 101;
//     flex-direction: column;
//     background-color: #ffffff;
//     padding-right: 0;
// }

// .info-page .caption,
// .info-page-c .caption {
//     display: flex;
//     flex: 0 1 auto;
//     height: 28px;
//     min-height: 28px;
//     align-items: center;
//     padding-bottom: 32px;
// }

// .info-page .caption div:first-child,
// .info-page-c .caption div:first-child {
//     flex: 1 1 auto;
//     display: flex;
//     overflow-x: hidden;
//     padding-right: 25px;
// }

// .info-page .caption div:last-child,
// .info-page-c .caption div:last-child {
//     width: 22px;
//     text-align: right;
//     display: flex;
//     justify-content: end;
//     cursor: pointer;
//     padding-right: 32px;
// }

// .info-page .content,
// .info-page-c .content {
//     overflow-y: auto;
//     padding-right: 32px;
//     font-family: "Jost*";
//     font-style: normal;
//     font-weight: 500;
//     font-size: 14px;
//     line-height: 150%;
//     letter-spacing: 0.3px;
//     color: #1f1f1f !important;
// }

// .info-page .scroll-to-top,
// .info-page-c .scroll-to-top {
//     position: absolute;
//     right: 32px;
//     bottom: 32px;
//     width: 20px;
//     height: 20px;
//     cursor: pointer;
// }

// .siw-main-header .beacon-container {
//     display: none;
// }

// #okta-sign-in .auth-content {
//     padding-top: 20px !important;
// }

// #ot-sdk-btn.ot-sdk-show-settings {
//     position: absolute !important;
//     left: 32px !important;
//     bottom: 32px !important;
//     background: transparent !important;
//     border: 1px solid #ffffff !important;
//     color: #fff !important;
//     width: 132px !important;
//     height: 40px !important;
//     font-family: "Jost*" !important;
//     font-style: normal !important;
//     font-weight: 500 !important;
//     font-size: 14px !important;
//     line-height: 150% !important;
//     text-align: center !important;
//     letter-spacing: 0.5px !important;
//     padding: 0 !important;
// }

// .auth-footer {
//     display: flex !important;
//     flex-direction: column !important;
//     flex-wrap: nowrap !important;
//     justify-content: space-around !important;
//     align-items: center !important;
// }

// .auth-footer .link {
//     font-family: "Jost*" !important;
//     font-style: normal !important;
//     font-weight: 500 !important;
//     font-size: 14px !important;
//     line-height: 150% !important;
//     text-align: center;
//     letter-spacing: 0.5px !important;
//     color: #13233a !important;
//     text-decoration: none !important;
//     box-shadow: none !important;
// }

// .auth-footer .js-cancel {
//     position: relative;
//     padding-left: 20px !important;
// }

// a.link.js-cancel:before {
//     content: " ";
//     background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.25 6L1.25 6M1.25 6L6.25 1M1.25 6L6.25 11' stroke='%2313233A' stroke-width='1.2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
//     width: 10px;
//     height: 10px;
//     display: block;
//     position: absolute;
//     left: 0;
//     top: 9px;
//     background-size: contain;
//     background-repeat: no-repeat;
// }

// .eyeicon.button-show:before {
//     content: " " !important;
//     background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.5 5L19.5 21M18.1905 16.1878C19.3634 15.2341 20.6217 14.0295 22 12.5616M2 12.5616C7.21052 18.1401 10.6978 20.3385 15.1568 18.186C15.379 18.0672 15.6141 17.9182 15.8245 17.7758M9.61905 6.53204C10.4141 6.24681 11.2013 6.10738 12 6.12068M12 6.12068V3M12 6.12068C13.3632 6.14339 14.7598 6.61111 16.2857 7.5584M16.2857 7.5584C17.9857 8.61381 19.8463 10.2645 22 12.5582M16.2857 7.5584L17.7143 5.81222M2 12.5582C3.68499 10.7542 5.18978 9.30373 6.60549 8.23953M11.8077 9C13.8469 9 15.5 10.567 15.5 12.5M8.61538 10.5646C8.22656 11.119 8 11.7844 8 12.5C8 14.433 9.6531 16 11.6923 16C12.4663 16 13.1847 15.7742 13.7782 15.3884' stroke='%2313233A' stroke-width='1.2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") !important;
//     width: 20px !important;
//     height: 20px !important;
//     background-size: contain !important;
//     background-repeat: no-repeat !important;
// }

// .eyeicon.button-hide.visibility-off- *:before {
//     background: transparent !important;
// }

// .eyeicon.button-hide:before {
//     content: " " !important;
//     background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 12.5616C7.21052 18.1401 10.6978 20.3385 15.1568 18.186C17.6822 16.7348 20.7712 13.8295 22 12.5582C19.8463 10.2645 17.9857 8.61381 16.2857 7.5584M12 6.12068V3M12 6.12068C13.3632 6.14339 14.7598 6.61111 16.2857 7.5584M12 6.12068C10 6.12068 7.96484 7.29372 7 8M16.2857 7.5584L17.7143 5.81222M2 12.5582C3.68499 10.7542 5.58429 9.06421 7 8M7 8L5.5 6M11.8077 9C13.8469 9 15.5 10.567 15.5 12.5M8.61538 10.5646C8.22656 11.119 8 11.7844 8 12.5C8 14.433 9.6531 16 11.6923 16C12.4663 16 13.1847 15.7742 13.7782 15.3884' stroke='%2313233A' stroke-width='1.2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") !important;
//     width: 20px !important;
//     height: 20px !important;
//     background-size: contain !important;
//     background-repeat: no-repeat !important;
// }

// .spec-table {
//     width: 100%;
//     border-collapse: collapse;
// }

// .spec-table tr {
//     height: 48px;
//     border-radius: 0px 0px 4px 4px;
// }

// .spec-table th {
//     background: #f1f1f1;
//     font-weight: bold;
//     text-align: left;
//     padding-left: 16px;
//     width: 25%;
// }

// .spec-table tr td {
//     padding-left: 16px;
// }

// .spec-table a {
//     color: #13233a !important;
// }

// .spec-table tr:hover {
//     background-color: #e0dfe0;
// }

// .o-form-fieldset-container a {
//     margin-top: 13px !important;
//     border: 0 !important;
//     background: transparent !important;
//     box-shadow: none !important;
// }

// div#ot-sdk-cookie-policy * {
//     color: #1f1f1f !important;
//     font-family: "Jost*" !important;
//     font-weight: 500 !important;
//     font-style: normal !important;
//     line-height: 150% !important;
// }

// div#ot-sdk-cookie-policy p {
//     color: #1f1f1f !important;
//     font-family: "Jost*" !important;
//     font-weight: 500 !important;
//     font-style: normal !important;
//     line-height: 150% !important;
//     font-size: 14px !important;
// }

// div#ot-sdk-cookie-policy h3 {
//     margin-bottom: 0.5em;
// }

// div#ot-sdk-cookie-policy th {
//     background-color: #f1f1f1 !important;
//     font-family: "Jost*" !important;
//     font-style: normal !important;
//     font-size: 14px !important;
//     line-height: 150% !important;
// }

// div#ot-sdk-cookie-policy td {
//     font-family: "Jost*" !important;
//     font-style: normal !important;
//     font-size: 14px !important;
//     line-height: 150% !important;
// }

// .explore-button {
//     text-align: center;
//     margin-top: 22px;
// }

// .explore-button a {
//     background: transparent;
//     border: 1px solid #ffffff;
//     color: #fff;
//     width: 240px;
//     height: 24px;
//     font-family: "Jost*";
//     font-style: normal;
//     font-weight: 500;
//     font-size: 14px;
//     line-height: 150%;
//     text-align: center;
//     letter-spacing: 0.5px;
//     padding: 10px;
//     text-decoration: none;
//     display: inline-block;
//     white-space: nowrap;
//     vertical-align: middle;
//     border-radius: 2px;
// }

// .explore-button a>* {
//     vertical-align: middle;
// }

// .explore-button a>svg {
//     padding-right: 10px;
// }

// .please-sign-in {
//     font-family: "Jost*";
//     font-style: normal;
//     font-weight: 500;
//     font-size: 16px;
//     line-height: 140%;
//     color: #13233a;
// }

// .okta-sign-in-header {
//     padding: 30px 0 !important;
//     text-align: center !important;
// }

// @media only screen and (max-height: 751px) {
//     #okta-sign-in {
//         margin-top: 1.5rem !important;
//     }
// }

@media only screen and (max-width: 600px) {
    #okta-sign-in {
        width: 80% !important;
        left: 40px !important;
        margin-top: 1.5rem !important;
    }    
}

@media only screen and (max-width: 320px) {
    #okta-sign-in {
        left: 8px !important;
        margin-top: 1.5rem !important;
    }
}

// .okta-form-title.o-form-head {
//     display: block;
// }

// #okta-sign-in .o-form-head {
//     text-align: left;
//     text-transform: none;
//     margin: 0;
//     font-family: "Jost*" !important;
//     font-style: normal;
//     font-weight: 500 !important;
//     font-size: 14px;
//     line-height: 140%;
//     color: #13233a !important;
// }