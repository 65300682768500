.footer-wrapper {
  background: $primary-white;
  padding-top: 28px;
  padding-bottom: 28px;
  padding-left: 100px;
  width: 100%;

  @media screen and (max-width: 440px) {
    padding-left: 85px;
    padding-right: 20px;
  }

  &.myaccount-footer {
    display: block;
    position: fixed;
    bottom: 0;


    @media screen and (min-width:430px) and (max-width: 480px) {
      padding-top: 75px;
    }
  }

  &.login-footer {
    position: sticky;
    top: 87%;
   
    @media screen and (min-width:430px) and (max-width: 480px) {
      padding-top: 75px;
      padding-bottom: 100px;
    }

    @media screen and (min-height:900px) {
      top: 89%;
    }
  }

  &.signin-footer {   
    position: fixed;
    top: 87%;

    @media screen and (min-height:900px) {
      top: 89%;
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;

    .modal-header {
      font-family: "Jost*";
      font-style: normal;
      font-weight: 300;
      font-size: 32px;
      line-height: 140%;
      color: $eternal-blue;
      justify-content: space-between !important;
    }

    .modal-body {
      font-family: "Jost*" !important;
      font-style: normal !important;
      font-weight: 650 !important;
      font-size: 14px !important;
      line-height: 150% !important;
      letter-spacing: 0.5px !important;
      color: #1f1f1f !important;

      h3,
      h4,
      div,
      p {
        color: $eternal-blue !important;
        font-family: "Jost*" !important;
        font-style: normal !important;
        font-weight: 650 !important;
        font-size: 14px !important;
        line-height: 150% !important;
        letter-spacing: 0.5px !important;
      }

      table {
        width: 100% !important;
        border-collapse: collapse !important;

        tr {
          height: 48px !important;
          border-radius: 0 0 4px 4px !important;

          &:hover {
            background-color: #e0dfe0 !important;
          }

          th {
            background: #f1f1f1 !important;
            font-weight: bold !important;
            text-align: left !important;
            padding-left: 16px !important;
            width: 25% !important;
            color: #1f1f1f !important;
          }

          td {
            padding-left: 16px !important;
            color: $eternal-blue !important;

            span {
              color: $eternal-blue !important;
            }

            a {
              color: $eternal-blue !important;
              background-color: transparent !important;
            }
          }
        }
      }
    }

    .links {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      gap: 4px;
      z-index: 200;

      .single-link {
        cursor: pointer;
        font-family: "Jost*" !important;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        color: $grey-700;
        letter-spacing: 0.5px;
      }

      .divider {
        color: $grey-700;
        font-family: "Jost*" !important;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
      }
    }

    .copyright {
      color: $grey-1000;
      font-family: "Jost*" !important;
      font-style: normal;
      font-weight: 650;
      font-size: 10px;
      line-height: 120%;
      letter-spacing: 0.5px;
      margin-top: 13px;
    }
  }
}